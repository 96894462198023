import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="footer-1 ptb-60 gradient-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
              <a href="#" className="navbar-brand mb-2">
                <img
                  src="assets/img/idatahost_logo_ Ash.png"
                  alt="logo"
                  className="img-fluid"
                />
              </a>
              <br />
              <p>
                We serve Web hosting, VPS, Dedicated Server, domain registering
                service for you. Most affordable, reliable and easily managed.
              </p>
              <ul className="list-inline social-list-default background-color social-hover-2 mt-2">
                <li className="list-inline-item">
                  <a className="twitter" href="https://twitter.com/idata_host">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="facebook"
                    href="https://www.facebook.com/idatahost">
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="linkedin"
                    href="https://www.linkedin.com/company/idatahost/">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="dribbble" href="https://dribbble.com/idatahost">
                    <i className="fab fa-dribbble"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-12 col-lg-8">
              <div className="row mt-0">
                <div className="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <h6 className="font-weight-normal">Domains</h6>
                  <ul>
                    <li>
                      <a href="/domain-checker">Domain Name Search</a>
                    </li>
                    <li>
                      <a href="/domain-transfer">Domain Transfer</a>
                    </li>
                    <li>
                      <a href="#">Personal Domain</a>
                    </li>
                    <li>
                      <a href="#">Whois Lookup</a>
                    </li>
                    <li>
                      <a href="/domain-registration">Domain Registration</a>
                    </li>
                    <li>
                      <a href="#">Manage Domain</a>
                    </li>
                    <li>
                      <a href="#">Cheap SSL Certificate</a>
                    </li>
                    <li>
                      <a href="/domain-registration">Buy Domain Name</a>
                    </li>
                    <li>
                      <a href="#">WHOIS Checker</a>
                    </li>
                    <li>
                      <a href="#">Free SSL Certificate</a>
                    </li>
                  </ul>
                </div>

                <div className="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <h6 className="font-weight-normal">Server</h6>
                  <ul>
                    <li>
                      <a href="/dedicated-server">Dedicated Server</a>
                    </li>
                    <li>
                      <a href="/cloud-hosting">Cloud Server</a>
                    </li>
                    <li>
                      <a href="#">Storage Server</a>
                    </li>
                    <li>
                      <a href="/cloud-hosting">High-Performance VPS</a>
                    </li>
                    <li>
                      <a href="/vps-server">IDH VPS Server</a>
                    </li>
                    <li>
                      <a href="/cloud-hosting">Virtual Dedicated Server</a>
                    </li>
                    <li>
                      <a href="/vps-server">VPS Server</a>
                    </li>
                    <li>
                      <a href="/cloud-hosting">IDH Cloud Server</a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3">
                  <h6 className="font-weight-normal">Licence</h6>
                  <ul>
                    <li>
                      <a href="#">cPanel VPS Server Licence</a>
                    </li>
                    <li>
                      <a href="#">cPanel Dedicated Server Licence</a>
                    </li>
                    <li>
                      <a href="#">DirectAdmin VPS Server Licence</a>
                    </li>
                    <li>
                      <a href="#">DirectAdmin Dedicated Server Licence</a>
                    </li>
                    <li>
                      <a href="#">WHMCS Licence(unlimited user)</a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <h6 className="font-weight-normal">Help Center</h6>
                  <ul>
                    <li>
                      <a href="#">FAQ</a>
                    </li>
                    <li>
                      <a href="https://kb.idatahost.com/">Knowledge Base</a>
                    </li>
                    <li>
                      <a href="https://cp.idatahost.com/login">Submit Ticket</a>
                    </li>
                    <li>
                      <a href="https://news.idatahost.com/">News</a>
                    </li>
                    <li>
                      <a href="https://blog.idatahost.com/">Blogs</a>
                    </li>
                    <li>
                      <a href="/contact">Report Abuse</a>
                    </li>
                    <li>
                      <a href="https://kb.idatahost.com/">Tutorial</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "2%" }}>
            <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
              <h5 style={{ color: "white" }}>We Accept</h5>
              <ul className="">
                <li className="list-inline-item">
                  <a className="" href="#">
                    <i className="fab fa-cc-paypal fa-3x"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="" href="#">
                    <i className="fab fa-cc-mastercard fa-3x"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="" href="#">
                    <i className="fab fa-cc-visa fa-3x"></i>
                  </a>
                </li>
              </ul>
              <ul className="">
                <li className="list-inline-item">
                  <a className="" href="#">
                    <i className="fab fa-apple-pay fa-3x"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="" href="#">
                    <i className="fab fa-amazon-pay fa-3x"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="" href="#">
                    <i className="fab fa-cc-amex fa-3x"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-12 col-lg-8">
              <div className="row mt-0">
                <div className="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <h6 className="font-weight-normal">Hosting</h6>
                  <ul>
                    <li>
                      <a href="/shared-hosting">Shared Hosting</a>
                    </li>
                    <li>
                      <a href="/shared-hosting">Wordpress Hosting</a>
                    </li>
                    <li>
                      <a href="/google-coming-soon">Web Hosting for School</a>
                    </li>
                    <li>
                      <a href="/java-hosting">Tomcat Hosting</a>
                    </li>
                    <li>
                      <a href="/cpanel-reseller-hosting">Reseller Hosting</a>
                    </li>
                    <li>
                      <a href="/vps-hosting">VPS Hosting</a>
                    </li>
                    <li>
                      <a href="/java-hosting">JSP Hosting</a>
                    </li>
                    <li>
                      <a href="/dedicated-server">Dedicated Hosting</a>
                    </li>
                    <li>
                      <a href="/shared-hosting">Application Hosting</a>
                    </li>
                  </ul>
                </div>

                <div className="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <h6 className="font-weight-normal"></h6>
                  <ul>
                    <li>
                      <a href="/website-builder">Website Builder</a>
                    </li>
                    <li>
                      <a href="/java-hosting">Java Hosting</a>
                    </li>
                    <li>
                      <a href="/wordpress-hosting">CMS Hosting</a>
                    </li>
                    <li>
                      <a href="/vps-hosting">Ecommerce Hosting</a>
                    </li>
                    <li>
                      <a href="/shared-hosting">cPanel Hosting</a>
                    </li>
                    <li>
                      <a href="/shared-hosting">Email Hosting</a>
                    </li>
                    <li>
                      <a href="/cheap-web-hosting">Cheap Web Hosting</a>
                    </li>
                    <li>
                      <a href="/website-builder">Web Design Services</a>
                    </li>
                    <li>
                      <a href="/free-hosting-for-school">Student Hosting</a>
                    </li>
                  </ul>
                </div>

                <div className="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <h6 className="font-weight-normal">WordPress</h6>
                  <ul>
                    <li>
                      <a href="/shared-hosting">Shared Hosting</a>
                    </li>
                    <li>
                      <a href="/wordpress-hosting">Wordpress Hosting</a>
                    </li>
                    <li>
                      <a href="/wordpress-hosting-migration">
                        Free Migrate Wordpress
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-sm-6 col-md-3 col-lg-3">
                  <h6 className="font-weight-normal">Legal</h6>
                  <ul>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#">Terms of Service</a>
                    </li>
                    <li>
                      <a href="#">Refund Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* Footer bottom */}

      <div className="footer-bottom py-3 gray-light-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-7">
              <div className="copyright-wrap small-text">
                <p className="mb-0">&copy; iDataHost, All rights reserved</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <a className="small-text" href="terms-condition.html">
                      Terms & Condition
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="small-text" href="#">
                      Security
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="small-text" href="privacy-policy.html">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Move to top */}

      <div
        className="scroll-top scroll-to-target primary-bg text-white"
        data-target="html">
        <span className="fas fa-hand-point-up"></span>
      </div>
    </div>
  );
};

export default Footer;
