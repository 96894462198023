import logo from "./logo.svg";
import "./App.css";
import RouterLinks from "./RouterLinks";

import TagManager from "react-gtm-module";

import { BrowserRouter as Router } from "react-router-dom";

const tagManagerArgs = {
  gtmId: "GTM-57HL7MW",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <Router>
      <div>
        <RouterLinks />
      </div>
    </Router>
  );
}

export default App;
