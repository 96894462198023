import React from "react";
import MetaTags from "react-meta-tags";

const Cloud_Hosting = () => {
  return (
    <div>
      <MetaTags>
        <title>Dedicated Cloud Hosting Plans | iDataHost</title>
        <meta
          name="description"
          content="Idatahost is the best web hosting provider"
        />
        <meta
          name="keywords"
          content="Cloud server, Cloud hosting, VPS hosting, web hosting, domain names, web site, search engine optimization, hosting, servers"
        />
      </MetaTags>
      <div className="main">
        {/*hero section start*/}
        <section className="ptb-120 gradient-bg">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7 col-lg-6">
                <div className="hero-content-wrap text-white">
                <div className="headline mb-4">
                  <p className="mb-0">
                    <i className="fas fa-bell rounded-circle mr-2"></i>
                    <span className="font-weight-bold">$50 Off! </span> On
                    Cloud Hosting!
                  </p>
                </div>
                  <h1 className="text-white">Dedicated Cloud Server</h1>
                  <p className="lead">
                    Enjoy Dedicated Cloud Server With 100% Dedicated resources
                    and 100% Scalability services with full time support.
                  </p>
                  <a href="#dedi-cld-srvr" className="btn btn-brand-03 btn-lg">
                    Get Start Now
                  </a>
                </div>
              </div>
              <div className="col-md-5 col-lg-6">
                <div className="img-wrap">
                  <img
                    src="assets/img/hero-cloud-hosting.svg"
                    alt="vps image"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*hero section end*/}

        {/*compare pricing table start*/}
        <section className="pricing-section ptb-100 gray-light-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-4">
                  <h2 id="dedi-cld-srvr">Cloud Server Flexible Pricing Plan</h2>
                  <p>
                    Best deal for different type of Application, Database
                    Hosting, SaaS, or Large Reseller web hosting businesss and
                    much more!
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-md-center justify-content-center">
              <div className="col-lg-3 col-md-6 col-sm-8">
                <div className="popular-price text-center bg-white single-pricing-pack-2 mt-4 rounded border">
                  <h6 className="mb-4">DCS-Economy</h6>
                  <div className="pricing-price pb-4">
                    <div className="h3 mb-0">
                      $56.00 <span className="price-cycle h4">/mo</span>
                      <br />
                    </div>
                    <div>
                      <span className="pricing-onsale ">
                        $50 Off -
                        <span className="badge color-3 color-3-bg priceTextWp">
                          $286/quarter
                        </span>
                      </span>
                    </div>
                  </div>
                  <div style={{ textAlign: "left" }} className="pricing-price">
                    <ul className="list-unstyled tech-feature-list">
                      <li className="py-1">
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <strong>E3-1240v3</strong>
                      </li>
                      <li className="py-1">
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <strong>RAM</strong> 4GB (up to 6)
                      </li>
                      <li className="py-1">
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <strong>Core</strong> 2 (up to 6)
                      </li>
                      <li className="py-1">
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <strong>Disk</strong> 200GB (up to 1TB)
                      </li>
                      <li className="py-1">
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <strong>Traffic</strong> 5TB (up to 20TB)
                      </li>
                    </ul>
                  </div>
                  <a
                    href="https://cp.idatahost.com/cart.php?a=add&pid=144"
                    className="btn btn-brand-01"
                    target="_blank">
                    Order Now
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-8">
                <div className="popular-price text-center bg-white single-pricing-pack-2 mt-4 rounded border">
                  <h6 className="mb-4">DCS-Business</h6>
                  <div className="pricing-price pb-4">
                    <div className="h3 mb-0">
                      $62 <span className="price-cycle h4">/mo</span>
                      <br />
                    </div>
                    <div>
                      <span className="pricing-onsale ">
                        $50 Off -
                        <span className="badge color-3 color-3-bg priceTextWp">
                          $322/quarter
                        </span>
                      </span>
                    </div>
                  </div>
                  <div style={{ textAlign: "left" }} className="pricing-price">
                    <ul className="list-unstyled tech-feature-list">
                    <li className="py-1">
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <strong>E3-1240v5</strong>
                      </li>
                      <li className="py-1">
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <strong>RAM</strong> 4GB (up to 6)
                      </li>
                      <li className="py-1">
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <strong>Core</strong> 2 (up to 6)
                      </li>
                      <li className="py-1">
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <strong>Disk</strong> 200GB (up to 1TB)
                      </li>
                      <li className="py-1">
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <strong>Traffic</strong> 5TB (up to 20TB)
                      </li>
                    </ul>
                  </div>
                  <a
                    href="https://cp.idatahost.com/cart.php?a=add&pid=68"
                    className="btn btn-brand-01"
                    target="_blank">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*compare pricing table end*/}

        {/*feature section start*/}
        <section className="cta-new ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-6">
                <div className="cta-new-wrap">
                  <h2>Features of Dedicated Cloud Server</h2>
                  <p className="lead">
                    Our leading support team is available to you 24 hours a day,
                    7 days a week, 365 days a year. Reach out to us via Live
                    Chat or a support ticket at any time if you need assistance
                    or have questions, and you will receive a prompt answer from
                    a real person.
                  </p>
                  <ul className="list-unstyled tech-feature-list">
                    <li className="py-1">
                      <span className="ti-control-forward mr-2 color-primary"></span>
                      Flexible, High performance hardware
                    </li>
                    <li className="py-1">
                      <span className="ti-control-forward mr-2 color-primary"></span>
                      Root Access
                    </li>
                    <li className="py-1">
                      <span className="ti-control-forward mr-2 color-primary"></span>
                      level3 Bandwidth
                    </li>
                    <li className="py-1">
                      <span className="ti-control-forward mr-2 color-primary"></span>
                      <strong>99.97%</strong> Uptime Guarantee
                    </li>
                    <li className="py-1">
                      <span className="ti-control-forward mr-2 color-primary"></span>
                      24/7 Support via Ticket/Live Chat/Phone/E-mail
                    </li>
                  </ul>
                  <div className="action-btns mt-4">
                    <a
                      href="https://cp.idatahost.com/store/cloud-server"
                      className="btn btn-brand-01">
                      Get Started Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="cta-new-wrap">
                  <img
                    src="assets/img/private-cloud-server.svg"
                    alt="hosting"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="feature-tab-section ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="cta-new-wrap">
                  <img
                    src="assets/img/easy-to-transfer.svg"
                    alt="hosting"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="cta-new-wrap">
                  <h2>Benefits of a Cloud Server</h2>
                  <div className="feature-feature-list py-3">
                    <h5 className="mb-2">Project Delivery</h5>
                    <p>
                      Our dedicated technical team, experienced in delivering
                      projects on time and on budget discount policy, allows a
                      complete business continuity.
                    </p>
                  </div>
                  <div className="feature-feature-list py-3">
                    <h5 className="mb-2">20x Time Faster</h5>
                    <p>
                      Boost the traffic to your project. Get up to 20x faster
                      hosting on our fine-tuned servers
                    </p>
                  </div>
                  <div className="feature-feature-list py-3">
                    <h5 className="mb-2">Full Control</h5>
                    <p>
                      You will get root access to your Cloud server. You can
                      manage and control your server as you want.
                    </p>
                  </div>
                  <div className="feature-feature-list py-3">
                    <h5 className="mb-2">SSH key management</h5>
                    <p>
                      Our Cloud Control Portal makes it simple to create and
                      import SSH keys on your server. The SSH Keys of the user's
                      choice can also be configured to be applied and added by
                      default to future servers.
                    </p>
                  </div>
                  <div className="feature-feature-list py-3">
                    <h5 className="mb-2">Instant Scalability</h5>
                    <p>
                      Cloud servers are particularly advantageous because they
                      are flexible and adaptable. We can instantly increase the
                      dedicated capacity on your cloud server at any time. Do
                      you need more RAM? More processing power? It's over!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*feature section end*/}

        {/*call to action new style start*/}
        <section className="ptb-60 primary-bg">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-lg-6">
                <div className="cta-content-wrap text-white">
                  <h2 className="text-white">
                    24/7 Customer Support over Tickets/Phone/Email/Chat
                  </h2>
                  <p>
                    Our experts are always here to give answers and advice to
                    you to set up and enlarge your online store. Experts are
                    available 24 hours a day, 7 days a week, to assist you. Open
                    a ticket or call over the phone.
                  </p>
                </div>
                <div className="support-action d-inline-flex flex-wrap">
                  <a href="https://cp.idatahost.com/login" className="mr-3">
                    <i className="fas fa-comment mr-1 color-accent"></i>
                    <span>Create a ticket</span>
                  </a>
                  <a href="tel:+12135100797" className="mb-0">
                    <i className="fas fa-phone-alt mr-1 color-accent"></i>
                    <span>+12135100797</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-none d-lg-block">
                <div className="cta-img-wrap text-center">
                  <img
                    src="assets/img/call-center-support.svg"
                    width="250"
                    className="img-fluid"
                    alt="server room"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*call to action new style end*/}

        {/*faq new style start*/}
        <section id="faq" className="ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-5">
                  <h2>Dedicated Cloud Server FAQs</h2>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-12">
                <div id="accordion" className="accordion faq-wrap">
                  <div className="card mb-3">
                    <a
                      className="card-header"
                      data-toggle="collapse"
                      href="#collapse0"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        What is Cloud Server?
                      </h6>
                    </a>
                    <div
                      id="collapse0"
                      className="collapse show"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          Once a virtual private server (VPS) running in a cloud
                          computing environment that is called a cloud server.
                          It is built and delivered from a cloud computing
                          platform. This cloud server possible to connect
                          remotely. Virtual Private Server (VPS) is also known
                          as a cloud server. All necessary software run and can
                          function as individual units in a cloud server.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <a
                      className="card-header"
                      data-toggle="collapse"
                      href="#collapse0"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        What is Dedicated Cloud Server?
                      </h6>
                    </a>
                    <div
                      id="collapse0"
                      className="collapse show"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          A virtual private server (VPS) needs some resources
                          for running the VPS machine. Some resources possible
                          to share with other VPS machines. CPU Core, RAM, Dis
                          Space, Bandwidth these resources possible to share
                          with other VPS. i Data Host will not share these
                          resources with other VPS. These resources include CPU
                          Cores, RAM, Disk Space, and Network Port Speeds, all
                          of which are completely dedicated to your server.
                          Since resources can be added as required, Cloud
                          Servers are more flexible than Dedicated Servers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card my-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse1"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        What is the use of cloud server?
                      </h6>
                    </a>
                    <div
                      id="collapse1"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          A cloud server is a high-capacity physical or virtual
                          infrastructure that stores applications and websites.
                          Cloud services may include web hosting, data sharing,
                          and the use of software or apps. The cloud server
                          reduces the server cost.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card my-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse2"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        Why cloud server is the best?
                      </h6>
                    </a>
                    <div
                      id="collapse2"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          For some benefits, the cloud server is the best. 1.
                          Efficiency 2. Cost saving 3. Data Security 4.
                          Scalability 5. Flexibility 6. Mobility 7. Quality
                          Control 8. Competitive Edge 9. Disaster Recovery 10.
                          Automatic Software Updates 11. Sustainability 12. Loss
                          Prevention
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card mt-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse3"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        Do I have full control of my cloud server?
                      </h6>
                    </a>
                    <div
                      id="collapse3"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          iDATA HOST Cloud Server comes with root access, and
                          any iDATA HOST Windows Cloud Server comes with full
                          administrator access, giving you complete control over
                          your server and the freedom to customize it however
                          you want. iDATA HOST believes in giving you as many
                          choices as possible, so we let you completely
                          configure your server right out of the box or
                          customize it later. Simply start using the Operating
                          System that has been installed for you.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card mt-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse3"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        Why is cloud server so important?
                      </h6>
                    </a>
                    <div
                      id="collapse3"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          One of the most valuable advantages of cloud computing
                          is the ability to store data in the cloud. The cloud
                          will store any related business data, making it more
                          available and usable. However, the data is accessible
                          from any computer and from any location in the world,
                          making it suitable for remote employees and offices.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card mt-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse3"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        Why choose iDATA HOST?
                      </h6>
                    </a>
                    <div
                      id="collapse3"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          obviously! You have to choose iDATA HOST for cloud
                          server. Because we provide you all kind of facilities
                          which are not provide any hosting provider. Also we
                          give you speed customization, security in high demand.
                          We provide all time services which is control our best
                          service group, they are always ready to give you the
                          best services that makes a great impact in your cloud
                          server.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*faq new style end*/}
      </div>
    </div>
  );
};

export default Cloud_Hosting;
