import React from "react";
import MetaTags from "react-meta-tags";

const DedicatedServer = () => {
  return (
    <div>
      <MetaTags>
        <title>Dedicated Server Hosting - Order a best web server | iDataHost.com </title>
        <meta
          name="description"
          content="Order a fully customized and powerful Dedicated Hosting Server from iDataHost. Our datacenter located in Europe. Advantages & promotions available."
        />
        <meta
          name="keywords"
          content="Best dedicated hosting, dedicated hosting service, linux dedicated server, dedicated server hosting, dedicated hosting price, dedicated server web hosting, dedicated server providers, dedicated hosting provider, cpanel dedicated server, fast dedicated servers"
        />
      </MetaTags>
      <div className="main">
        {/*hero section start*/}
        <section className="ptb-120 gradient-bg">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7 col-lg-6">
                <div className="hero-content-wrap text-white">
                  <h1 className="text-white">
                    Satisfactory Dedicated Server Hosting Managed by Experts
                  </h1>
                  <p className="lead">
                    Custom Designed, High Performance Dedicated Hosting Server
                    at cheap price.
                  </p>
                  <a href="#dedi_plan" className="btn btn-brand-03 btn-lg">
                    Get Start Now
                  </a>
                </div>
              </div>
              <div className="col-md-5 col-lg-6">
                <div className="img-wrap">
                  <img
                    src="assets/img/hero-vps-hosting.svg"
                    alt="vps image"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*hero section end*/}

        {/*vps hosting pricing plane start*/}
        <section className="vps-pricing-plane ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-5">
                  <h2 id="dedi_plan">Choose Your Dedicated Hosting Server Plan</h2>
                  <p className="lead">
                    Fully Managed Dedicated Server Hosting. Get Leading Hardware
                    at an Affordable Price for Dedicated Server.
                    Enthusiastically deliver competitive systems and
                    client-centric.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table className="table vps-hosting-pricing-table">
                  <thead className="primary-bg text-white">
                    <tr>
                      <th>SERVER</th>
                      <th>CORE</th>
                      <th>RAM</th>
                      <th>STORAGE</th>
                      <th>BANDWIDTH</th>
                      <th>GPU</th>
                      <th colspan="2">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="vps-pricing-row gray-light-bg">
                      <td data-value="SERVER">E5-1620V4</td>
                      <td data-value="CORE">4 Cores @ 3.5 GHz
                      <span className="cpu-core">Benchmark 4,097</span>
                      </td>
                      <td data-value="RAM">
                        <span>32GB </span><br/>
                        <span className="cpu-core">up to 256GB</span>
                      </td>
                      <td data-value="STORAGE">
                        2x 240GB SSD
                        <span className="cpu-core">up to 4 disks</span>
                      </td>
                      {/*
                      <td data-value="Geekbench Score">
                        <div className="score-bar-wrap">
                          <span className="geekbench-score">4097</span>
                        </div>
                      </td>
                      */}
                      <td data-value="Bandwidth">
                      <span>3Gbps </span><br/><span className="cpu-core">30TB/mo free traffic</span>
                      </td>
                      <td>
                        &nbsp;
                      </td>
                      <td data-value="Price">
                        <p className="mb-0">
                          <span className="rate">
                            $120.00<span>/month</span>
                          </span>
                          <span className="pricing-onsale">
                            On sale -
                            <span className="badge color-3 color-3-bg">
                              Save 10%
                            </span>
                          </span>
                        </p>
                      </td>
                      <td>
                        <a
                          href="https://cp.idatahost.com/cart.php?a=add&pid=152"
                          className="btn btn-brand-01 btn-xs">
                          Configure & Order Now
                        </a>
                      </td>
                    </tr>
                    <tr className="vps-pricing-row gray-light-bg">
                      <td data-value="SERVER">E3-1240v3</td>
                      <td data-value="CORE">4 Cores @ 3.4 GHz
                      <span className="cpu-core">Benchmark 3,604</span>
                      </td>
                      <td data-value="RAM">
                        <span>16GB </span><br/>
                        <span className="cpu-core">up to 32GB</span>
                      </td>
                      <td data-value="STORAGE">
                        2x 240GB SSD
                        <span className="cpu-core">up to 8 disks</span>
                      </td>
                      {/*
                      <td data-value="Geekbench Score">
                        <div className="score-bar-wrap">
                          <span className="geekbench-score">4097</span>
                        </div>
                      </td>
                      */}
                      <td data-value="Bandwidth">
                      <span>1Gbps </span><br/><span className="cpu-core">30TB/mo free traffic</span>
                      </td>
                      <td>
                      <span className="cpu-core">Optional <br/>Up to 1 GPU</span>
                      </td>
                      <td data-value="Price">
                        <p className="mb-0">
                          <span className="rate">
                            $130.00<span>/month</span>
                          </span>
                          <span className="pricing-onsale">
                            On sale -
                            <span className="badge color-3 color-3-bg">
                              Save 10%
                            </span>
                          </span>
                        </p>
                      </td>
                      <td>
                        <a
                          href="https://cp.idatahost.com/cart.php?a=add&pid=58"
                          className="btn btn-brand-01 btn-xs">
                          Configure & Order Now
                        </a>
                      </td>
                    </tr>
                    <tr className="vps-pricing-row gray-light-bg">
                      <td data-value="SERVER">E3-1240v5</td>
                      <td data-value="CORE">4x 2x 3.5GHz
                      <span className="cpu-core">Benchmark 4,147</span>
                      </td>
                      <td data-value="RAM">
                        <span>32GB </span><br/>
                        <span className="cpu-core">up to 64GB</span>
                      </td>
                      <td data-value="STORAGE">
                        2x 240GB SSD
                        <span className="cpu-core">up to 8 disks</span>
                      </td>
                      {/*
                      <td data-value="Geekbench Score">
                        <div className="score-bar-wrap">
                          <span className="geekbench-score">4097</span>
                        </div>
                      </td>
                      */}
                      <td data-value="Bandwidth">
                      <span>3Gbps </span><br/><span className="cpu-core">30TB/mo free traffic</span>
                      </td>
                      <td>
                        &nbsp;
                      </td>
                      <td data-value="Price">
                        <p className="mb-0">
                          <span className="rate">
                            $138.00<span>/month</span>
                          </span>
                          <span className="pricing-onsale">
                            On sale -
                            <span className="badge color-3 color-3-bg">
                              Save 10%
                            </span>
                          </span>
                        </p>
                      </td>
                      <td>
                        <a
                          href="https://cp.idatahost.com/cart.php?a=add&pid=96"
                          className="btn btn-brand-01 btn-xs">
                          Configure & Order Now
                        </a>
                      </td>
                    </tr>
                    <tr className="vps-pricing-row gray-light-bg">
                      <td data-value="SERVER">E5-1650v2</td>
                      <td data-value="CORE">6 Cores @ 3.5 GHz
                      <span className="cpu-core">Benchmark 4,880</span>
                      </td>
                      <td data-value="RAM">
                        <span>32GB </span><br/>
                        <span className="cpu-core">up to 64GB</span>
                      </td>
                      <td data-value="STORAGE">
                        2x 240GB SSD
                        <span className="cpu-core">up to 8 disks</span>
                      </td>
                      {/*
                      <td data-value="Geekbench Score">
                        <div className="score-bar-wrap">
                          <span className="geekbench-score">4097</span>
                        </div>
                      </td>
                      */}
                      <td data-value="Bandwidth">
                      <span>1Gbps </span><br/><span className="cpu-core">30TB/mo free traffic</span>
                      </td>
                      <td>
                      <span className="cpu-core">Optional <br/>Up to 1 GPU</span>
                      </td>
                      <td data-value="Price">
                        <p className="mb-0">
                          <span className="rate">
                            $200.00<span>/month</span>
                          </span>
                          <span className="pricing-onsale">
                            On sale -
                            <span className="badge color-3 color-3-bg">
                              Save 10%
                            </span>
                          </span>
                        </p>
                      </td>
                      <td>
                        <a
                          href="https://cp.idatahost.com/cart.php?a=add&pid=149"
                          className="btn btn-brand-01 btn-xs">
                          Configure & Order Now
                        </a>
                      </td>
                    </tr>
                    <tr className="vps-pricing-row gray-light-bg">
                      <td data-value="SERVER">E5-1660v3</td>
                      <td data-value="CORE">8 Cores @ 3 GHz
                      <span className="cpu-core">Benchmark 7,241</span>
                      </td>
                      <td data-value="RAM">
                        <span>64GB </span><br/>
                        <span className="cpu-core">up to 256GB</span>
                      </td>
                      <td data-value="STORAGE">
                        2x 240GB SSD
                        <span className="cpu-core">up to 8 disks</span>
                      </td>
                      {/*
                      <td data-value="Geekbench Score">
                        <div className="score-bar-wrap">
                          <span className="geekbench-score">4097</span>
                        </div>
                      </td>
                      */}
                      <td data-value="Bandwidth">
                      <span>1Gbps </span><br/><span className="cpu-core">30TB/mo free traffic</span>
                      </td>
                      <td>
                      <span className="cpu-core">Optional <br/>Up to 1 GPU</span>
                      </td>
                      <td data-value="Price">
                        <p className="mb-0">
                          <span className="rate">
                            $235.00<span>/month</span>
                          </span>
                          <span className="pricing-onsale">
                            On sale -
                            <span className="badge color-3 color-3-bg">
                              Save 10%
                            </span>
                          </span>
                        </p>
                      </td>
                      <td>
                        <a
                          href="https://cp.idatahost.com/cart.php?a=add&pid=35"
                          className="btn btn-brand-01 btn-xs">
                          Configure & Order Now
                        </a>
                      </td>
                    </tr>
                    <tr className="vps-pricing-row gray-light-bg">
                      <td data-value="SERVER">2X E5-2630 LV4</td>
                      <td data-value="CORE">20 Cores @ 1.8 GHz
                      <span className="cpu-core">Benchmark 10,341</span>
                      </td>
                      <td data-value="RAM">
                        <span>128GB </span><br/>
                        <span className="cpu-core">up to 768GB</span>
                      </td>
                      <td data-value="STORAGE">
                        2x 3TB SSD
                        <span className="cpu-core">up to 12 disks</span>
                      </td>
                      {/*
                      <td data-value="Geekbench Score">
                        <div className="score-bar-wrap">
                          <span className="geekbench-score">4097</span>
                        </div>
                      </td>
                      */}
                      <td data-value="Bandwidth">
                      <span>3Gbps </span><br/><span className="cpu-core">30TB/mo free traffic</span>
                      </td>
                      <td>
                      <span className="cpu-core">Optional upto 1GPU</span>
                      </td>
                      <td data-value="Price">
                        <p className="mb-0">
                          <span className="rate">
                            $440.00<span>/month</span>
                          </span>
                          <span className="pricing-onsale">
                            On sale -
                            <span className="badge color-3 color-3-bg">
                              Save 10%
                            </span>
                          </span>
                        </p>
                      </td>
                      <td>
                        <a
                          href="https://cp.idatahost.com/cart.php?a=add&pid=143"
                          className="btn btn-brand-01 btn-xs">
                          Configure & Order Now
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        {/*dedicated hosting pricing plane end*/}

        {/*feature section start*/}
        <section className="feature-section ptb-100 gray-light-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-5">
                  <h2>Best Features of Bare Metal Dedicated Server Hosting</h2>
                  <p className="lead">
                    To handle your specific tasks build a fully managed
                    satisfactory dedicated server. Let us help you create the
                    best server for your budget.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6">
                <div className="cta-new-wrap">
                  <div className="feature-feature-list py-3">
                    <span className="ti-harddrives icon-size-md d-block color-primary mb-3"></span>
                    <h5 className="mb-2">SSD Disk Drives</h5>
                    <p>
                      solid-state drive (SSD) is storage device, which is new
                      generation used in computers. SSD is faster than HDD.
                      SSD's speed up computers significantly and more reliable.
                      SSD uses integrated circuit assemblies to store data
                      persistently, functioning as secondary storage in the
                      hierarchy of computer storage and typically using flash
                      memory.
                    </p>
                  </div>
                  <div className="feature-feature-list py-3">
                    <span className="ti-rss-alt icon-size-md d-block color-primary mb-3"></span>
                    <h5 className="mb-2">Optimized Network</h5>
                    <p>
                      Our Dedicated Server does not have costly downtime and
                      balancing loading speed. Rest assured that your services
                      are up and running, with our data-based service uptime
                      guarantee. We make sure we have redundancies in the
                      network and prevent network errors.
                    </p>
                  </div>
                  <div className="feature-feature-list py-3">
                    <span className="ti-lock icon-size-md d-block color-primary mb-3"></span>
                    <h5 className="mb-2">Security</h5>
                    <p>
                      With full root access you will be able to take full
                      control of your server very easily. Our technicians will
                      routinely monitor the server to check for any issues.
                      Comprehensive defenses, integral to your company's
                      security.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="cta-new-wrap">
                  <img
                    src="assets/img/3.png"
                    alt="hosting"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*feature section end*/}

        {/*call to action new section start*/}

        {/*call to action new section end*/}

        {/*faq section start*/}
        <section id="faq" className="ptb-100 primary-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div
                  className="section-heading text-center mb-2"
                  style={{ color: "white" }}>
                  <h2 style={{ color: "white" }}>Dedicated Server FAQs</h2>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-12">
                <div id="accordion" className="accordion faq-wrap">
                  <div className="card mb-3">
                    <a
                      className="card-header"
                      data-toggle="collapse"
                      href="#collapse0"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        What is a Dedicated Server?
                      </h6>
                    </a>
                    <div
                      id="collapse0"
                      className="collapse show"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          Dedicated hosting is a form of website hosting that
                          gives you the most flexibility, security, and control
                          over your resources. Dedicated hosting is entirely
                          separated from one another, giving users total control
                          over how they configure their server without
                          influencing other users. We offer Dedicated Server
                          Hosting plans for a competitive price.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card my-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse1"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        Do I really need a dedicated hosting??
                      </h6>
                    </a>
                    <div
                      id="collapse1"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          It may be time to consider a dedicated server if your
                          website is loading slowly, running out of room, or
                          your audience is rapidly expanding. This article on
                          dedicated hosting and how it compares to other
                          services could be of interest to you.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card my-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse2"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        How do I get started with dedicated hosting?
                      </h6>
                    </a>
                    <div
                      id="collapse2"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          It's simple to get started with dedicated hosting.
                          Simply choose one of our normal, enhanced, or premium
                          dedicated hosting packages based on the services your
                          website requires and follows the sign-up instructions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card my-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse2"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        What can do with a dedicated server hosting?
                      </h6>
                    </a>
                    <div
                      id="collapse2"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          Dedicated hosting refers to business-class servers
                          that are fully run. They're ideal for
                          resource-intensive applications and large websites.
                          With dedicated server hosting, you have complete
                          control over how your hosting environment is
                          configured, making it ideal for large-scale hosting
                          requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card mt-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse3"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        What are dedicated hosting benefits?
                      </h6>
                    </a>
                    <div
                      id="collapse3"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          The main advantages of dedicated hosting are control,
                          privacy, and assured services. Dedicated hosting, on
                          the other hand, is not shared with any other
                          customers, ensuring that the dedicated server hosting
                          customer's website defines the duties and efficiently.
                          Dedicated hosting is also the best option for
                          customers with high-traffic websites.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*faq section end*/}
      </div>
    </div>
  );
};

export default DedicatedServer;
