import React from "react";
import MetaTags from "react-meta-tags";

const WordpressHosting = () => {
  return (
    <div>
      <MetaTags>
        <title>Fast, secured, Low Cost WordPress Hosting | iDataHost</title>
        <meta
          name="description"
          content="Get fast, secured, and high-performing WordPress hosts from iData Host. Just order and ready to go in minutes, we offer WP Host plans based on different kinds of business."
        />
        <meta
          name="keywords"
          content="WP web host, WordPress Hosting, Ecommerce hosting, woocommerce, wordpress, hosting, web hosting"
        />
      </MetaTags>
      <div className="main">
        {/*hero section start*/}
        <section className="ptb-120 gradient-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-6">
                <div className="hero-content-wrap text-white position-relative">
                <div className="headline mb-4">
                  <p className="mb-0">
                    <i className="fas fa-bell rounded-circle mr-2"></i>
                    <span className="font-weight-bold">Up to $122 Off! </span> On
                    WordPress Hosting!
                  </p>
                </div>
                  <h1 className="text-white">
                    Feel Like Host WordPress Locally
                  </h1>
                  <p className="lead">
                    (WP Host) WordPress hosting made easy & affordable,
                    choose a fine-tuned web hosting services solution for
                    successful personal and business websites.
                  </p>
                  <a href="#wp-host-anch" className="btn btn-brand-03 btn-lg">
                    Get Start Now
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="img-wrap">
                  <img
                    src="assets/img/wp-hosting.svg"
                    alt="email hosting"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*hero section end*/}

        {/*pricing section start*/}
        <section id="pricing" className="pricing-section ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-4">
                  <h2 id="wp-host-anch">Wordpress Hosting</h2>
                  <p className="lead">
                    Build your website with WP Host. WordPress Hosting for
                    ecommerce, Personal profile, or for your business profile.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-md-center justify-content-center">
              <div className="col-lg-3 col-md-6 col-sm-8">
                <div className="popular-price text-center bg-white single-pricing-pack-2 price-2 mt-4 rounded border">
                  <h6 className="mb-4">Wp-Starter</h6>
                  <div className="pricing-price pb-4">
                    <div className="h3 mb-0">
                      $3.78 <span className="price-cycle h4">/mo</span>
                      <br />
                    </div>
                    <div>
                      <span className="pricing-onsale ">
                        $22 Off -
                        <span className="badge color-3 color-3-bg priceTextWp">
                          $23.36/year
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="pricing-content">
                    <ul
                      style={{ textAlign: "left" }}
                      className="list-unstyled tech-feature-list">
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>1</span>&nbsp; Website
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>10 GB</span> Storage
                      </li>

                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>Free</span>&nbsp; SSL
                      </li>
                      <li>
                        {" "}
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        24/7 Support
                      </li>
                      <li>
                        {" "}
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        Managed WordPress
                      </li>
                      <li>
                        {" "}
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        99.9% Uptime
                      </li>
                      <li>
                        {" "}
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        FREE CDN
                      </li>
                      <li>
                        {" "}
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        Auto Backups
                      </li>
                    </ul>
                    <a
                      href="https://cp.idatahost.com/cart.php?a=add&pid=78"
                      className="btn btn-brand-01"
                      target="_blank">
                      Order Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-8">
                <div className="feature-price text-center bg-white single-pricing-pack-2 price-2 mt-4 rounded border">
                  <span className="label-corner primary-bg">Featured</span>
                  <h6 className="mb-4">Wp-Ecommerce</h6>
                  <div className="pricing-price pb-4">
                    <div className="h3 mb-0">
                      $15.78 <span className="price-cycle h4">/mo</span>
                      <br />
                    </div>
                    <div>
                      <span className="pricing-onsale ">
                        $122 Off -
                        <span className="badge color-3 color-3-bg priceTextWp">
                          $67.36/year
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="pricing-content">
                    <ul
                      style={{ textAlign: "left" }}
                      className="list-unstyled tech-feature-list">
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>1</span>&nbsp; Website
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>150 GB</span> Storage
                      </li>

                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>Free</span>&nbsp; SSL
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        24/7 Support
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        Managed WordPress
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        99.9% Uptime
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        FREE CDN
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        Auto Backups
                      </li>
                    </ul>
                    <a
                      href="https://cp.idatahost.com/cart.php?a=add&pid=73"
                      className="btn btn-brand-01"
                      target="_blank">
                      Order Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-8">
                <div className="popular-price text-center bg-white single-pricing-pack-2 price-2 mt-4 rounded border">
                  <h6 className="mb-4">Wp-Turbo</h6>
                  <div className="pricing-price pb-4">
                    <div className="h3 mb-0">
                      $6.78 <span className="price-cycle h4">/mo</span>
                      <br />
                    </div>
                    <div>
                      <span className="pricing-onsale ">
                        $47 Off! -
                        <span className="badge color-3 color-3-bg priceTextWp">
                          $34.36/year
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="pricing-content">
                    <ul
                      style={{ textAlign: "left" }}
                      className="list-unstyled tech-feature-list">
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>1</span>&nbsp; Website
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>50 GB</span> Storage
                      </li>

                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>Free</span>&nbsp; SSL
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        24/7 Support
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        Managed WordPress
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        99.9% Uptime
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        FREE CDN
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        Auto Backups
                      </li>
                    </ul>
                    <a
                      href="https://cp.idatahost.com/cart.php?a=add&pid=74"
                      className="btn btn-brand-01"
                      target="_blank">
                      Order Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-8">
                <div className="popular-price text-center bg-white single-pricing-pack-2 price-2 mt-4 rounded border">
                  <h6 className="mb-4">Wp-cPanel</h6>
                  <div className="pricing-price pb-4">
                    <div className="h3 mb-0">
                      $10.78 <span className="price-cycle h4">/mo</span>
                      <br />
                    </div>
                    <div>
                      <span className="pricing-onsale ">
                        $80 Off! -
                        <span className="badge color-3 color-3-bg priceTextWp">
                          $49.36/year
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="pricing-content">
                    <ul
                      style={{ textAlign: "left" }}
                      className="list-unstyled tech-feature-list">
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>50</span>&nbsp; Website
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>100 GB</span> Storage
                      </li>

                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>Free</span>&nbsp; SSL
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        24/7 Support
                      </li>
                      <li>
                      <span className="fas fa-check-circle text-success mr-2"></span>
                        Managed WordPress
                        </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        99.9% Uptime
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        FREE CDN
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        Auto Backups
                      </li>
                    </ul>
                    <a
                      href="https://cp.idatahost.com/cart.php?a=add&pid=158"
                      className="btn btn-brand-01"
                      target="_blank">
                      Order Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*pricing section end*/}

        {/*promo-section feature section start*/}
        <section className="promo-feature ptb-100 gray-light-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-5">
                  <h2>Managed WP and Features</h2>
                  <p className="lead">
                    Manage from cPanel WordPress or get premium WordPress
                    Hosting and get these fabulous features. Moving wordPress
                    site to new domain is totally free.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="feature-feature-list p-3 text-center">
                  <span className="ti-thumb-up icon-size-lg d-block color-primary mb-3"></span>
                  <h5 className="mb-2">99.9% Network Uptime</h5>
                  <p>Guaranteed uptime confirm by our expert and machine. </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="feature-feature-list p-3 text-center">
                  <span className="ti-headphone-alt icon-size-lg d-block color-primary mb-3"></span>
                  <h5 className="mb-2">24x7 Tech Support</h5>
                  <p>
                    Worry-free service. Our experts always ready to help you.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="feature-feature-list p-3 text-center">
                  <span className="ti-lock icon-size-lg d-block color-primary mb-3"></span>
                  <h5 className="mb-2">Security and safety</h5>
                  <p>Ensuring complete WordPress security and free SSL!</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="feature-feature-list p-3 text-center">
                  <span className="ti-user icon-size-lg d-block color-primary mb-3"></span>
                  <h5 className="mb-2">Bloggers Hosting</h5>
                  <p>Best web hosting for bloggers in 2021</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*promo-section feature section end*/}

        {/*feature section start*/}
        <section className="feature-section ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-5">
                  <h2>Best Website Hosting for Wordpress</h2>
                  <p className="lead">
                    We are maintaining the best WordPress servers and bringing
                    lots of features for WordPress hosting.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="features-box border p-4 rounded">
                  <div className="features-box-icon mb-3">
                    <span className="ti-panel icon-size-md color-primary"></span>
                  </div>
                  <div className="features-box-content">
                    <h5>FTP Access</h5>
                    <p>
                      Use FTP to access a remote computer on the network to
                      receive files or to send files. You are required to enter
                      a username and password to access an FTP server, just like
                      logging in to your computer.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="features-box border p-4 rounded">
                  <div className="features-box-icon mb-3">
                    <span className="ti-thumb-up icon-size-md color-primary"></span>
                  </div>
                  <div className="features-box-content">
                    <h5>Faster than standard WordPress</h5>
                    <p>
                      Our WordPress hosting is 3x faster than standard
                      WordPress. Deliver your WordPress website at optimal
                      speed. A faster website helps your visitor to stay a long
                      time and help your site to rank.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="features-box border p-4 rounded">
                  <div className="features-box-icon mb-3">
                    <span className="ti-mouse-alt icon-size-md color-primary"></span>
                  </div>
                  <div className="features-box-content">
                    <h5>1-Click Installer</h5>
                    <p>
                      It is more popular because it makes your job to set up a
                      new website so much easier. You can easily install
                      WordPress with a single click and start building an
                      awesome website in minutes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="features-box border p-4 rounded">
                  <div className="features-box-icon mb-3">
                    <span className="ti-timer icon-size-md color-primary"></span>
                  </div>
                  <div className="features-box-content">
                    <h5>Automatic backup</h5>
                    <p>
                      We take regular offsite backups of your websites in a
                      week. You will get the last three backups always. We are
                      ensuring that your hard work is protected by our experts
                      and backup machines.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="features-box border p-4 rounded">
                  <div className="features-box-icon mb-3">
                    <span className="ti-harddrives icon-size-md color-primary"></span>
                  </div>
                  <div className="features-box-content">
                    <h5>Free website migration</h5>
                    <p>
                      Our migration specialists can be migrated your current
                      WordPress website for totally free, with no downtime. Our
                      experts are very capable to migrate your website without
                      losing data and any problems.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="features-box border p-4 rounded">
                  <div className="features-box-icon mb-3">
                    <span className="ti-cup icon-size-md color-primary"></span>
                  </div>
                  <div className="features-box-content">
                    <h5>Free SSL certificate</h5>
                    <p>
                      All of our hosting plans include free Let's Encrypt SSL
                      certificates for all of your domains, enabling you to use
                      HTTPS for added protection, SEO and keep your website
                      secured.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*feature section end*/}

        {/*call to action new section start*/}
        <section className="ptb-60 primary-bg">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-lg-6">
                <div className="cta-content-wrap text-white">
                  <h2 className="text-white">
                    24/7 Customer Support over Tickets/Phone/Email/Chat
                  </h2>
                  <p>
                    Our experts are always here to give answers and advice to
                    you to set up and enlarge your online store. Experts are
                    available 24 hours a day, 7 days a week, to assist you. Just
                    open a ticket or call over the phone.
                  </p>
                </div>
                <div className="support-action d-inline-flex flex-wrap">
                  <a href="https://cp.idatahost.com/login" className="mr-3">
                    <i className="fas fa-comment mr-1 color-accent"></i>
                    <span>Create a ticket</span>
                  </a>
                  <a href="tel:+12135100797" className="mb-0">
                    <i className="fas fa-phone-alt mr-1 color-accent"></i>
                    <span>+(121)35100797</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-none d-lg-block">
                <div className="cta-img-wrap text-center">
                  <img
                    src="assets/img/call-center-support.svg"
                    width="250"
                    className="img-fluid"
                    alt="server room"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*call to action new section end*/}

        {/*faq section start*/}
        <section id="faq" className="ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-5">
                  <h2>WordPress Hosting FAQs</h2>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-12">
                <div id="accordion" className="accordion faq-wrap">
                  <div className="card mb-3">
                    <a
                      className="card-header"
                      data-toggle="collapse"
                      href="#collapse0"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        What is WordPress?
                      </h6>
                    </a>
                    <div
                      id="collapse0"
                      className="collapse show"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          WordPress is one of the most widely used content
                          management systems (CMS). In reality, WordPress now
                          powers 28% of all websites on the internet. It is used
                          by companies of all sizes (from small businesses to
                          large corporations to major news organizations) to
                          establish a strong online presence.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card my-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse1"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        What is WordPress Hosting?
                      </h6>
                    </a>
                    <div
                      id="collapse1"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          WordPress hosting is a shared hosting or dedicated
                          hosting service that includes the WordPress CMS
                          pre-installed. For simplicity, secure and accelerate a
                          WordPress installation compared to a WordPress
                          installation on a conventional Linux server. It has
                          been optimized to better satisfy the performance and
                          security demands of WordPress. In some control panel
                          like cPanel includes one click installs to make it
                          easy to get started with WordPress.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card my-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse2"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        What does optimized wordpress hosting mean?
                      </h6>
                    </a>
                    <div
                      id="collapse2"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          It means we've designed our hosting platform and
                          architecture specifically for hosting WordPress
                          websites from the ground up.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card mt-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse3"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        what version of wordpress am I using?
                      </h6>
                    </a>
                    <div
                      id="collapse3"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          Don't worry if you're not sure which WordPress edition
                          your site is running. After signing into your site,
                          simply go to your WordPress dashboard to search your
                          latest WordPress edition. The dashboard's latest
                          version number can be found in the bottom-right
                          corner. And you can update any time from dashboard.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card my-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse2"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        Is there any difficult setup I need to worry about?
                      </h6>
                    </a>
                    <div
                      id="collapse2"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          There aren't any. Our team of WordPress experts will
                          take care of it for you. After you've made your order,
                          all you have to do now is log in and start filling
                          your new website with content.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*faq section end*/}
      </div>
    </div>
  );
};

export default WordpressHosting;
