import React from "react";

const PriceSection = () => {
  return (
    <div>
      <section className="pricing-section ptb-100 gray-light-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading text-center mb-4">
                <h2>Our Flexible Pricing Plan</h2>
                <p>
                  Professional hosting at an affordable price. Distinctively
                  recaptiualize principle-centered core competencies through
                  client-centered core competencies.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-md-center justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="text-center bg-white single-pricing-pack-2 mt-4 rounded border">
                <div className="pricing-icon">
                  <img
                    src="assets/img/dadicate-web-hosting.svg"
                    width="60"
                    alt="hosing"
                  />
                </div>
                <h4 className="package-title">Wordpress Hosting</h4>
                <div className=" pb-4">
                  <div className="h3 mb-0">
                    $3.79 <span className="price-cycle h4">/mo</span>
                    <br />
                  </div>
                  <div>
                    <span className="pricing-onsale ">
                      50% Off - <strong>$1.89/mo</strong>
                      <br />
                      <span className="badge color-3 color-3-bg priceTextWp">
                        $22.74/1st year
                      </span>
                    </span>
                  </div>
                </div>

                <div className="pricing-price">
                  <ul
                    style={{ textAlign: "left" }}
                    className="list-unstyled tech-feature-list">
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>10 GB</strong> SSD Storage
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>3</strong> Websites
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>50k</strong> Visitor/Mo
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>Free</strong> SSL
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>Free</strong> CDN
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>Auto</strong> Backup
                    </li>
                  </ul>
                  <a
                    href="https://cp.idatahost.com/login"
                    className="btn btn-brand-01"
                    target="_blank">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="popular-price text-center bg-white single-pricing-pack-2 mt-4 rounded border">
                <div className="pricing-icon">
                  <img
                    src="assets/img/vps-hosting.svg"
                    width="60"
                    alt="hosing"
                  />
                </div>
                <h4 className="package-title">Shared Web Hosting</h4>
                <div className=" pb-4">
                  <div className="h3 mb-0">
                    $2.78 <span className="price-cycle h4">/mo</span>
                    <br />
                  </div>
                  <div>
                    <span className="pricing-onsale ">
                      45% Off - <strong>$1.53/mo</strong>
                      <br />
                      <span className="badge color-3 color-3-bg priceTextWp">
                        $18.35/1st year
                      </span>
                    </span>
                  </div>
                </div>
                <div className="pricing-price">
                  <ul
                    style={{ textAlign: "left" }}
                    className="list-unstyled tech-feature-list">
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>20</strong> GB SSD
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>4</strong> Websites
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>Free </strong>SSL
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>Free</strong> CDN
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>Free</strong> Domain
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>Auto</strong> Backup
                    </li>
                  </ul>
                  <a
                    href="https://cp.idatahost.com/login"
                    className="btn btn-brand-01"
                    target="_blank">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="text-center bg-white single-pricing-pack-2 mt-4 rounded border">
                <div className="pricing-icon">
                  <img
                    src="assets/img/cloud-hosting.svg"
                    width="60"
                    alt="hosing"
                  />
                </div>
                <h4 className="package-title">JAVA Hosting</h4>
                <div className=" pb-4">
                  <div className="h3 mb-0">
                    $8.99 <span className="price-cycle h4">/mo</span>
                    <br />
                  </div>
                  <div>
                    <span className="pricing-onsale ">
                      22% Off - <strong>$7/mo</strong>
                      <br />
                      <span className="badge color-3 color-3-bg priceTextWp">
                        $84.15/1st year
                      </span>
                    </span>
                  </div>
                </div>
                <div className="pricing-price">
                  <ul
                    style={{ textAlign: "left" }}
                    className="list-unstyled tech-feature-list">
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>100 GB</strong> Storage
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>1 GB</strong> RAM
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>2 TB</strong> Bandwidth
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>1 Core</strong> Processor
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>Root</strong> Access
                    </li>
                    <li className="py-1">
                      <span className="fas fa-check-circle text-success mr-2"></span>
                      <strong>24/7</strong> Support
                    </li>
                  </ul>
                  <a
                    href="https://cp.idatahost.com/login"
                    className="btn btn-brand-01"
                    target="_blank">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Information */}

      <section className="ptb-60 primary-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7 col-lg-6">
              <div className="cta-content-wrap text-white">
                <h2 className="text-white">
                  Best Cloud Hosting <br />
                  With Trusted Service
                </h2>
                <p>
                  Thousands of small and medium-sized enterprises are powered by
                  iDataHost cloud servers worldwide. For everything between
                  major IaaS projects and deployments, iDataHost is designed to
                  help you make progress. Get the 99.9% uptime SLA you deserve
                  with the best price and performance on the market.
                </p>
              </div>
              <div className="action-btns mt-4">
                <a href="/cloud-hosting" className="btn btn-brand-03">
                  {" "}
                  Get Start Now{" "}
                </a>
              </div>
            </div>
            <div className="col-md-5 col-lg-4">
              <div className="cta-img-wrap text-center">
                <img
                  src="assets/img/server-room.svg"
                  className="img-fluid"
                  alt="server room"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PriceSection;
