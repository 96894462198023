import React from "react";
import MetaTags from "react-meta-tags";

const Tomcat = () => {
  return (
    <div>
      <MetaTags>
        <title>JSP Hosting, Fast, Secure & Best Java Hosting 2021</title>
        <meta
          name="description"
          content="Faster Java Hosting On your private Servers. Best High performance Java Hosting Covers all Of your Apache Tomcat, JSP"
        />
        <meta
          name="keywords"
          content="java web hosting, java hosting, java host, jsp hosting, tomcat hosting, jvm hosting"
        />
      </MetaTags>
      <div className="main">
        {/*hero section start*/}
        <section className="ptb-120 gradient-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-6">
                <div className="hero-content-wrap text-white position-relative">
                <div className="headline mb-4">
                  <p className="mb-0">
                    <i className="fas fa-bell rounded-circle mr-2"></i>
                    <span className="font-weight-bold">Up to $81.56 Off! </span> On
                    JSP Hosting!
                  </p>
                </div>
                  <h1 className="text-white">
                    <img
                      src="assets/img/java-hosting.png"
                      width="80"
                      className="img-fluid"
                      alt="Java Hosting"
                    />
                    Java Hosting
                  </h1>
                  <p className="lead">
                    iDataHost Java Hosting. Fastest. Easiest. Most Reliable Best
                    Java Hosting Solution.
                  </p>
                  <a href="#jsp-plan-anchr" className="btn btn-brand-03 btn-lg">
                    Get Start Now
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="img-wrap">
                  <img
                    src="assets/img/wp-hosting.svg"
                    alt="email hosting"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*hero section end*/}

        {/*pricing section start*/}
        <section id="pricing" className="pricing-section ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-4">
                  <h2 id="jsp-plan-anchr">Tomcat based JAVA Hosting Plan</h2>
                  <p className="lead">
                    JSP hosting at an affordable price. Our JSP hosting is
                    ultra-fast and ready for you. Choose the high-speed package
                    that fits your needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-md-center justify-content-center">
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="popular-price text-center bg-white single-pricing-pack-2 price-2 mt-4 rounded border">
                  <h6 className="mb-4">JSP-Basic</h6>
                  <div className="pricing-price pb-4">
                    <div className="h3 mb-0">
                      $7.16 <span className="price-cycle h4">/mo</span>
                      <br />
                    </div>
                    <div>
                      <span className="pricing-onsale "><del>7.99</del><br/>
                        $10 Off! -
                        <span className="badge color-3 color-3-bg priceTextWp">
                          $85.88/year
                        </span>
                      </span>
                    </div>
                  </div>

                  <div className="pricing-content">
                    <ul
                      style={{ textAlign: "left" }}
                      className="list-unstyled tech-feature-list">
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>1 Core</span>&nbsp;Server
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span className="text-under-line">50 GB</span>
                        &nbsp;Storage
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        1 GB RAM
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span className="text-under-line">Private Server</span>
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        Root Access
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        1 TB Bandwidth
                      </li>
                    </ul>
                    <a
                      href="https://cp.idatahost.com/cart.php?a=add&pid=109"
                      className="btn btn-brand-01"
                      target="_blank">
                      Order Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="feature-price text-center bg-white single-pricing-pack-2 price-2 mt-4 rounded border">
                  <span className="label-corner primary-bg">Featured</span>
                  <h6 className="mb-4">JSP-Pro</h6>
                  <div className="pricing-price pb-4">
                    <div className="h3 mb-0">
                      $13.99 <span className="price-cycle h4">/mo</span>
                      <br />
                    </div>
                    <div>
                      <span className="pricing-onsale "><del>15.99</del><br/>
                        $24 Off! -
                        <span className="badge color-3 color-3-bg priceTextWp">
                          $167.88/year
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="pricing-content">
                    <ul
                      style={{ textAlign: "left" }}
                      className="list-unstyled tech-feature-list">
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>2 Core</span>&nbsp;Server
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span className="text-under-line">100 GB</span>
                        &nbsp;Storage
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                      4 GB RAM
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span className="text-under-line">Private Server</span>
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        Root Access
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        2 TB Bandwidth
                      </li>
                    </ul>
                    <a
                      href="https://cp.idatahost.com/cart.php?a=add&pid=159"
                      className="btn btn-brand-01"
                      target="_blank">
                      Order Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="popular-price text-center bg-white single-pricing-pack-2 price-2 mt-4 rounded border">
                  <h6 className="mb-4">JSP-Ultra</h6>
                  <div className="pricing-price pb-4">
                    <div className="h3 mb-0">
                      $15.99 <span className="price-cycle h4">/mo</span>
                      <br />
                    </div>
                    <div>
                      <span className="pricing-onsale "> <del>21.99</del><br/>
                        $72 Off! -
                        <span className="badge color-3 color-3-bg priceTextWp">
                          $191.88/year
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="pricing-content">
                    <ul
                      style={{ textAlign: "left" }}
                      className="list-unstyled tech-feature-list">
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span>4 Core</span>&nbsp;Server
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span className="text-under-line">150 GB</span>
                        &nbsp;Storage
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        6 GB RAM
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        <span className="text-under-line">Private Server</span>
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        Root Access
                      </li>
                      <li>
                        <span className="fas fa-check-circle text-success mr-2"></span>
                        3 TB Bandwidth
                      </li>
                    </ul>
                    <a
                      href="https://cp.idatahost.com/cart.php?a=add&pid=161"
                      className="btn btn-brand-01"
                      target="_blank">
                      Order Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*pricing section end*/}

        {/*promo-section feature section start*/}
        <section className="promo-feature ptb-100 gray-light-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-5">
                  <h2>Why iData Host Best For JAVA Hosting</h2>
                  <p className="lead">
                    Maximum ten Java hosting sites host in one server. Even our
                    customers can choose a dedicated hosting for their website.
                    iData Host is the best Java hosting service for 2021.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="feature-feature-list p-3 text-center">
                  <span className="ti-thumb-up icon-size-lg d-block color-primary mb-3"></span>
                  <h5 className="mb-2">99% Network Uptime</h5>
                  <p>Guaranteed uptime confirm by our expert and machines.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="feature-feature-list p-3 text-center">
                  <span className="ti-headphone-alt icon-size-lg d-block color-primary mb-3"></span>
                  <h5 className="mb-2">24x7 Tech Support</h5>
                  <p>
                    Worry-free service. Our experts always ready to help you.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="feature-feature-list p-3 text-center">
                  <span className="ti-lock icon-size-lg d-block color-primary mb-3"></span>
                  <h5 className="mb-2">Free SSL!</h5>
                  <p>Ensuring complete free SSL! security</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="feature-feature-list p-3 text-center">
                  <span className="ti-user icon-size-lg d-block color-primary mb-3"></span>
                  <h5 className="mb-2">Money-Back Guarantee</h5>
                  <p>
                    Give our high speed jvm hosting service a try completely
                    risk-free!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*promo-section feature section end*/}

        {/*feature section start*/}
        <section className="feature-section ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-5">
                  <h2>JAVA Hosting Features</h2>
                  <p className="lead">
                    iDataHost bring many features to all of our jsp hosting
                    plans. aaPanel java hosting gives a strong web hosting
                    flavor!
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="features-box border p-4 rounded">
                  <div className="features-box-icon mb-3">
                    <span className="ti-panel icon-size-md color-primary"></span>
                  </div>
                  <div className="features-box-content">
                    <h5>aaPanel Control Panel</h5>
                    <p>
                      aaPanel, a simple but powerful control panel, can manage
                      the web server through web-based GUI(Graphical User
                      Interface). aaPanel provides the one-click function such
                      as one-click install LNMP/LAMP developing environment and
                      software. This feature comes with our Dedicated Hosting.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="features-box border p-4 rounded">
                  <div className="features-box-icon mb-3">
                    <span className="ti-thumb-up icon-size-md color-primary"></span>
                  </div>
                  <div className="features-box-content">
                    <h5>Premium Bandwidth</h5>
                    <p>
                      Our Premium bandwidth generally refers to the high-quality
                      bandwidth that your server connection speed too fast.
                      Premium bandwidth speed up your server speed. We include
                      premium bandwidth with each dedicated server, Cloud
                      Server, VPS Server, and all kinds of web hosting.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="features-box border p-4 rounded">
                  <div className="features-box-icon mb-3">
                    <span className="ti-mouse-alt icon-size-md color-primary"></span>
                  </div>
                  <div className="features-box-content">
                    <h5>Free SSL Certificates!</h5>
                    <p>
                      Nowadays SSL certificates are mandatory! we have still got
                      your back. All Java web hosting plans come with free
                      Letsencrypt certificates for one year, and every year this
                      SSL certificate is automatically renewed without charge.
                      SSL certificate smooth your website traffic service.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="features-box border p-4 rounded">
                  <div className="features-box-icon mb-3">
                    <span className="ti-timer icon-size-md color-primary"></span>
                  </div>
                  <div className="features-box-content">
                    <h5>Automatic backup</h5>
                    <p>
                      We take regular offsite backups of your websites in a
                      week. You will get the last three backups always. We are
                      ensuring that your hard work is protected by our experts
                      and backup machines.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="features-box border p-4 rounded">
                  <div className="features-box-icon mb-3">
                    <span className="ti-harddrives icon-size-md color-primary"></span>
                  </div>
                  <div className="features-box-content">
                    <h5>Easy Installation</h5>
                    <p>
                      Tomcat is still the most highly used software solution in
                      the industry because it is popular with many modern
                      applications. It facilitates the execution of Applications
                      In java, and JSP-based web pages.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="features-box border p-4 rounded">
                  <div className="features-box-icon mb-3">
                    <span className="ti-cup icon-size-md color-primary"></span>
                  </div>
                  <div className="features-box-content">
                    <h5>Award-Winning Support</h5>
                    <p>
                      iDataHost support team and resources are available 24
                      hours a day, 7 days a week. Our customers love our ticket
                      and email support. Our team rates high in customer
                      satisfaction for their friendly expertise.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*feature section end*/}

        {/*call to action new section start*/}
        <section className="ptb-60 primary-bg">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-lg-6">
                <div className="cta-content-wrap text-white">
                  <h2 className="text-white">
                    24/7 Customer Support over Tickets/Phone/Email/Chat
                  </h2>
                  <p>
                    Our experts are always here to give answers and advice to
                    you to set up and enlarge your online store. Experts are
                    available 24 hours a day, 7 days a week, to assist you. Just
                    open a ticket or call over the phone.
                  </p>
                </div>
                <div className="support-action d-inline-flex flex-wrap">
                  <a href="https://cp.idatahost.com/login" className="mr-3">
                    <i className="fas fa-comment mr-1 color-accent"></i>
                    <span>Create a ticket</span>
                  </a>
                  <a href="tel:+12135100797" className="mb-0">
                    <i className="fas fa-phone-alt mr-1 color-accent"></i>
                    <span>+(121)35100797</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-none d-lg-block">
                <div className="cta-img-wrap text-center">
                  <img
                    src="assets/img/call-center-support.svg"
                    width="250"
                    className="img-fluid"
                    alt="server room"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*call to action new section end*/}

        {/*faq section start*/}
        <section id="faq" className="ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-5">
                  <h2>JAVA Hosting FAQs</h2>
                  <p>
                    Efficiently productivate reliable paradigms before
                    ubiquitous models. Continually utilize frictionless
                    expertise whereas tactical relationships. Still have
                    questions? Contact us
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-12">
                <div id="accordion" className="accordion faq-wrap">
                  <div className="card mb-3">
                    <a
                      className="card-header"
                      data-toggle="collapse"
                      href="#collapse0"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">What is tomcat?</h6>
                    </a>
                    <div
                      id="collapse0"
                      className="collapse show"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          JSP stands for Java application Pages, a specialize in
                          treating that enables you to create dynamic and
                          data-driven pages for Java Web Applications. The
                          Apache Software Foundation has created Tomcat, an
                          application server that runs Java servlets and
                          delivers web pages that contain coding relevant to the
                          Java Server Page. It's a developer-led open source
                          project. It can be downloaded in both binary and
                          source form from the Apache website.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card my-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse1"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        why do you use tomcat?
                      </h6>
                    </a>
                    <div
                      id="collapse1"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          you should have use tomcat because:
                          <br />
                          1.It is incredible lightweight
                          <br />
                          2.It is open sources
                          <br />
                          3.It is highly flexible
                          <br />
                          4.Your server will be more stable
                          <br />
                          5.It offers an extra level of security
                          <br />
                          6.It is mature
                          <br />
                          7.It is well documented
                          <br />
                          8.It is most widely used java application server
                          <br />
                          9.It is geared towards java-based
                          <br />
                          For those reason you must you tomcat.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card my-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse2"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">What is JSP?</h6>
                    </a>
                    <div
                      id="collapse2"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          JSP is a Java modern technique that allows you to
                          create responsive, information pages for your Java
                          operating systems. The Document Object requirement is
                          the foundation for JSP. Both technologies are
                          frequently used together, especially in older Java web
                          applications. JSP provides for Active Server Pages,
                          and it's a terms of diplomatic for designing dynamic
                          web pages. It is a servlet's extension.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card mt-3">
                    <a
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapse3"
                      aria-expanded="false">
                      <h6 className="mb-0 d-inline-block">
                        Can I use tomcat as a web server?
                      </h6>
                    </a>
                    <div
                      id="collapse3"
                      className="collapse"
                      data-parent="#accordion">
                      <div className="card-body white-bg">
                        <p>
                          In addition to being a servlet driver, Tomcat can also
                          be used as a web server. Tomcat also has SSL support,
                          which is close to how SSL is implemented in Web NMS.
                          As a result, clients who use SSL have nothing to worry
                          about. Since Tomcat is a Java application, using it as
                          a Web NMS web server in SSL mode is much easier.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*faq section end*/}
      </div>
    </div>
  );
};

export default Tomcat;
